import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";

import Colors from "src/styles/Colors";
import { Label1, Paragraph3 } from "src/components/Text";

import { FluidImage } from "src/Types";
import media from "src/styles/media";

type Props = {
  data: {
    arwing: FluidImage;
    customEmote: FluidImage;
    fruit: FluidImage;
    pizza: FluidImage;
    suhDude: FluidImage;
    greenCoin: FluidImage;
    smallTradeCircle: FluidImage;
  };
};

const Features: React.FC<Props> = ({ data }) => {
  return (
    <Wrapper>
      <FeatureBox>
        <Feature>
          <Label>own things</Label>
          <FeatureDescription>
            All NFTs are certifiably unique and ownable. You can really own your
            digital assets.
          </FeatureDescription>
        </Feature>
        <Feature>
          <Label>sell things</Label>
          <FeatureDescription>
            Ya don't own something unless you can sell it! You can sell your
            NFTs through any supported marketplace.
          </FeatureDescription>
        </Feature>
        <Feature>
          <Label>trade things</Label>
          <FeatureDescription>
            Ever trade a fruit cup for a slice of pizza? no? Well if those were
            digital, you could trade them ok.
          </FeatureDescription>
        </Feature>
      </FeatureBox>
      <Arwing>
        <Img fluid={data.arwing.childImageSharp.fluid} />
      </Arwing>
      <Emote>
        <Img fluid={data.customEmote.childImageSharp.fluid} />
      </Emote>
      <Dude>
        <Img fluid={data.suhDude.childImageSharp.fluid} />
      </Dude>
      <Rect>BUY NOW</Rect>
      <Circle>
        <Img fluid={data.smallTradeCircle.childImageSharp.fluid} />
      </Circle>
      <Fruit>
        <Img fluid={data.fruit.childImageSharp.fluid} />
      </Fruit>
      <Pizza>
        <Img fluid={data.pizza.childImageSharp.fluid} />
      </Pizza>
      <Coin1>
        <Img fluid={data.greenCoin.childImageSharp.fluid} />
      </Coin1>
      <Coin2>
        <Img fluid={data.greenCoin.childImageSharp.fluid} />
      </Coin2>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  height: 31.3vw;
  position: absolute;
  top: 62vw;
  left: 0;
  width: 100vw;
  padding: 5.3vw 0 0 0;
  display: flex;
  justify-content: center;
  ${media.tablet} {
    top: 282.8vw;
    height: unset;
  }
`;
const FeatureBox = styled.div`
  width: 72.2vw;
  height: 21.3vw;
  border: solid 1px ${Colors.green};
  background: ${Colors.charcoal};
  display: flex;
  justify-content: space-between;
  padding: 9.8vw 3.5vw 0;
  ${media.tablet} {
    border: none;
    width: 100%;
    height: unset;
    display: block;
    padding: 13.5vw 4.8vw 0;
  }
`;
const Feature = styled.div`
  width: 15.4vw;
  ${media.tablet} {
    width: 79.9vw;
    height: 83.1vw;
    border: solid 1px ${Colors.green};
    &:nth-child(1) {
      margin-bottom: 24.4vw;
      padding: 39.9vw 0 0 27vw;
      margin-left: 10.4vw;
    }
    &:nth-child(2) {
      margin-bottom: 31.4vw;
      padding: 34.8vw 15.5vw 0 6.3vw;
      width: 75.4vw;
      height: 75.4vw;
    }
    &:nth-child(3) {
      padding: 31.4vw 10.4vw 0 10.6vw;
      width: 74.6vw;
      height: 70vw;
      margin-left: 15.7vw;
    }
  }
`;
const Label = styled(Label1)`
  color: ${Colors.green};
  font-size: 1.7vw;
  ${media.tablet} {
    font-size: 6vw;
  }
`;
const FeatureDescription = styled(Paragraph3)`
  color: white;
`;
const Arwing = styled.div`
  width: 11.46vw;
  position: absolute;
  top: 0;
  left: 19.6vw;
  ${media.tablet} {
    width: 48.8vw;
    left: 30.2vw;
  }
`;
const Emote = styled.div`
  width: 11.4vw;
  position: absolute;
  top: 3.3vw;
  left: 10.4vw;
  ${media.tablet} {
    width: 48.8vw;
    top: 20.3vw;
    left: -10vw;
  }
`;
const Dude = styled.div`
  width: 12.1vw;
  position: absolute;
  top: 1.5vw;
  left: 41.5vw;
  ${media.tablet} {
    width: 43.2vw;
    top: 111.8vw;
    left: 50vw;
  }
`;

const Circle = styled.div`
  position: absolute;
  width: 4.2vw;
  top: 4.8vw;
  left: 74.2vw;
  transform: rotate(74.58deg);
  ${media.tablet} {
    width: 18vw;
    top: 229.1vw;
    left: 35vw;
  }
`;
const Fruit = styled.div`
  width: 13.6vw;
  position: absolute;
  top: 1.6vw;
  left: 63.3vw;
  ${media.tablet} {
    width: 46.9vw;
    top: 221.3vw;
    left: 0;
  }
`;
const Pizza = styled.div`
  width: 15.2vw;
  position: absolute;
  top: -0.3vw;
  left: 73.9vw;
  ${media.tablet} {
    width: 60vw;
    top: 205vw;
    left: 36.2vw;
  }
`;
const Rect = styled.div`
  position: absolute;
  top: 9vw;
  left: 39.7vw;
  width: 4vw;
  height: 1.7vw;
  border: 1px solid ${Colors.green};
  background: ${Colors.charcoal};
  color: ${Colors.green};
  font-size: 0.6vw;
  text-align: center;
  line-height: 300%;
  ${media.tablet} {
    width: 14.2vw;
    height: 6.1vw;
    font-size: 2.2vw;
    top: 111.3vw;
    left: 48.1vw;
  }
`;
const Coin1 = styled.div`
  width: 4.2vw;
  position: absolute;
  top: 5.3vw;
  left: 49.9vw;
  transform: rotate(18.48deg);
  ${media.tablet} {
    width: 11.3vw;
    top: 132.8vw;
    left: 81.9vw;
  }
`;
const Coin2 = styled.div`
  width: 3.3vw;
  position: absolute;
  top: 9.9vw;
  left: 51.6vw;
  transform: rotate(-38.71deg);
  ${media.tablet} {
    width: 13.3vw;
    top: 153.9vw;
    left: 62.8vw;
    transform: rotate(0deg);
  }
`;
export default Features;
