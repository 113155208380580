import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";

import Layout from "src/components/layout";
import SEO from "src/components/seo";

import AccessAnywhere from "src/sections/forUsers/AccessAnywhere";
import Features from "src/sections/forUsers/Features";

import { ReactComponent as UsersSVG } from "svg/usersSideways.svg";
import media from "src/styles/media";

const ForUsersPage = ({ data }) => {
  return (
    <Layout landingPage>
      <SEO title="Users" />
      <Users />
      <AccessAnywhere data={data} />
    </Layout>
  );
};

const Users = styled(UsersSVG)`
  position: absolute;
  left: 0;
  top: 0;
  width: 6.9vw;
  top: 7.8vw;
  ${media.tablet} {
    display: none;
  }
`;

export const pageQuery = graphql`
  query {
    arwing: file(relativePath: { eq: "arwing.png" }) {
      ...fluidImage
    }
    customEmote: file(relativePath: { eq: "customEmote.png" }) {
      ...fluidImage
    }
    pizza: file(relativePath: { eq: "pizza.png" }) {
      ...fluidImage
    }
    fruit: file(relativePath: { eq: "fruit.png" }) {
      ...fluidImage
    }
    suhDude: file(relativePath: { eq: "suhDude.png" }) {
      ...fluidImage
    }
    greenCoin: file(relativePath: { eq: "greenCoin.png" }) {
      ...fluidImage
    }
    smallTradeCircle: file(relativePath: { eq: "smallTradeCircle.png" }) {
      ...fluidImage
    }
  }
`;

export default ForUsersPage;
